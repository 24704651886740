import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { PageProps, Link } from "gatsby"

export default function Pokemon() {
    const [pokemon, setPokemon] = useState('pikachu')
    const [img, setImg] = useState(null)
    const [error, setError] = useState(null)

    useEffect(() => {
        document.title = 'Go ' + pokemon + "!"
    }, [pokemon])
    //TODO MIGUEL: Maybe accept any type of Upper Case and Lower case for API query
    //TODO MIGUEL: Find out how to add Debouncing so that we do not do an API query for every single letter
    useEffect(() => {
        let isCurrent = true
        fetch(`https://pokeapi.co/api/v2/pokemon/${pokemon}/`)
            .then(res => res.json())
            .then(res => {
                if (isCurrent) {
                    setPokemon(res.name)
                    setImg(res.sprites.front_default)
                }
            })
            .catch((emAll) => {
                setError(emAll)
            })
        //Cleanup function
        return () => {
            isCurrent = false
        }
    }, [pokemon, img])


    return <Layout>
        <SEO title="Pokemon" />
        <h1>Pokemon</h1>
        <p>Enter the name of your favorite Pokemon, all lower case:</p>
        <input onChange={(event) => {
            setPokemon(event.target.value)
        }} value={pokemon} type="text" />
        <div>Hello {pokemon}!</div>
        <br></br>
        {img && <img src={img} alt={pokemon} />}
        <br></br>
        <Link to="/">Go to home page</Link>
    </Layout>
}